var render = function render(){var _vm=this,_c=_vm._self._c;return (
      _vm.order.orderStatusId != 10 &&
        _vm.order.shippingAddress.zone.zoneGroupId == 5 &&
        _vm.order.deliveryStatusId &&
        _vm.order.deliveryStatusId != 100009 &&
        _vm.order.orderStatusId == 8
    )?_c('span',{staticClass:"status",class:'order-status-' + _vm.order.deliveryStatusId},[_vm._v("Spedito ")]):_c('span',{staticClass:"status",class:'order-status-' + _vm.order.orderStatusId},[_vm._v(_vm._s(_vm.orderStatus)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }