<template>
  <div
    class="payment-invoice flex-column"
    v-if="order.orderInfos && order.orderInfos.need_invoice == 'true'"
  >
    <v-card class="payment-invoice-card pa-6 mb-5" :loading="loading">
      <h3 class="invoice-title grey--text text--darken-2 pb-4">
        Informazioni di fatturazione
      </h3>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <template v-if="billingData.person.personTypeId == 1">
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.person.firstName"
                label="Nome"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.person.lastName"
                label="Cognome"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.person.vatFiscalCode"
                label="Codice Fiscale *"
                :rules="cfRules"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.person.emailCertified"
                label="@PEC"
                :rules="emailRules"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12"
              >Per modificare questi dati vai alla sezione
              <router-link :to="{ name: 'User' }"> profilo </router-link>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.email"
                label="Email *"
                :rules="emailPersonRules"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.person.company"
                label="Ragione sociale *"
                :rules="[requiredValue('Ragione sociale')]"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-if="billingData.person.personTypeId == 3"
                v-model="billingData.person.vatFiscalCode"
                label="Codice fiscale *"
                :rules="cfRules"
                required
                outlined
                dense
              ></v-text-field>
              <v-text-field
                readonly
                v-if="
                  billingData.person.personTypeId == 2 ||
                    billingData.person.personTypeId == 4
                "
                v-model="billingData.person.vatFiscalCode"
                label="Codice fiscale *"
                outlined
                dense
                required
                :rules="cfIvaRules"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.person.vatCode"
                label="Partita IVA *"
                :rules="cfIvaRules"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.email"
                label="Email *"
                :rules="emailPersonRules"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col
              cols="12"
              class="pb-0"
              v-if="billingData.person.personTypeId == 4"
            >
              <v-switch
                v-model="billingData.person.splitPayment"
                inset
                label="Split Payment"
              ></v-switch>
            </v-col> -->
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.billingAddress.province"
                :rules="[requiredValue('Provincia obbligatoria')]"
                label="Provincia *"
                required
                outlined
                dense
                maxlength="2"
              />
            </v-col>
            <v-col cols="8" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.billingAddress.address1"
                :rules="[requiredValue('Indirizzo obbligatorio')]"
                label="Indirizzo *"
                required
                outlined
                dense
              />
            </v-col>
            <v-col cols="4" sm="3" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.billingAddress.postalcode"
                label="Cap *"
                :rules="[requiredValue('Cap obbligatorio')]"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.billingAddress.addressNumber"
                label="Civico *"
                :rules="[requiredValue('Civico')]"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.billingAddress.city"
                label="Comune *"
                :rules="[requiredValue('Comune')]"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-select
                readonly
                v-model="billingData.billingAddress.country.countryId"
                item-text="name"
                item-value="countryId"
                placeholder="Nazione *"
                solo
                elevation="0"
                :items="customCountries"
                required
              />
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                readonly
                v-model="billingData.person.vatSdiCode"
                label="Codice destinatario"
                :rules="sdiRules"
                outlined
                dense
                @blur="$refs.form.validate()"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-form>
    </v-card>
  </div>
</template>
<style lang="scss">
.payment-invoice {
  .v-autocomplete {
    .v-input__icon {
      display: none;
    }
  }
}
.invoice-title {
  font-size: 31px;
  font-weight: normal;
}
</style>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

import RegistrationService from "~/service/userService";
import { req } from "../../validator/commons";
import { mapCartInfo } from "~/service/ebsn";
import {
  requiredValue,
  isEmail,
  isCF,
  isCfIva,
  isMobilePhoneStrict
} from "@/validator/validationRules";

export default {
  name: "OrderInvoice",
  props: {
    order: { type: Object, required: true }
  },
  data() {
    return {
      customCountries: global.config.customCountries,
      loading: false,
      valid: false,
      validPP: false,
      lazy: false,
      dirty: false,
      address: null,
      isMobilePhoneRules: [isMobilePhoneStrict(), requiredValue()],
      phoneConfirmed: false,
      billingData: {},
      billingDataPhone: {
        phone: null,
        prefix: "+39"
      },
      requiredValue: requiredValue,
      cfRules: [requiredValue(), isCF()],
      emailPersonRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailRules: [
        (this.billingData && !this.billingData.person.emailCertified) ||
          isEmail()
      ],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (this.billingData && !this.billingData.person.vatSdiCode) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (
            this.billingData &&
            !this.billingData.person.emailCertified
          ) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      cfIvaRules: [requiredValue(), isCfIva()],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapCartInfo({ sellCondition: "accept_sell_condition" }),
    ...mapGetters({
      needInvoice: "cart/needInvoice",
      warehouseId: "cart/getWarehouseId",
      serviceId: "cart/getSelectedService"
    }),
    disabledUpdate() {
      return !this.valid;
    }
  },
  methods: {
    async getUserDetail() {
      let _this = this;
      let res = await RegistrationService.getUserDetail();
      _this.billingData = res;
      if (res.phone && res.phone.length > 0) {
        this.phoneConfirmed = true;
      } else {
        this.phoneConfirmed = false;
      }
      this.billingData = res;
      this.billingData.prefix = "+39";
      this.billingDataPhone.phone = this.billingData.phone;
    },

    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    })
  },
  async mounted() {
    await this.getUserDetail();
    if (this.needInvoice) {
      this.validate();
    }
    if (typeof this.sellCondition === "undefined") {
      this.sellCondition = "false";
    }
  }
};
</script>
