<template>
  <span
    class="status"
    :class="'order-status-' + order.deliveryStatusId"
    v-if="
      order.orderStatusId != 10 &&
        order.shippingAddress.zone.zoneGroupId == 5 &&
        order.deliveryStatusId &&
        order.deliveryStatusId != 100009 &&
        order.orderStatusId == 8
    "
    >Spedito
  </span>

  <span class="status" :class="'order-status-' + order.orderStatusId" v-else
    >{{ orderStatus }}
  </span>
</template>
<style global lang="scss">
.status {
  color: #f39300;
  font-weight: bold;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 13px;
  }
}
.order-status-1,
.order-status-2,
.order-status-5,
.order-status-7,
.order-status-9,
.order-status-18 {
  color: #ffb800 !important;
}
.order-status-3,
.order-status-6,
.order-status-8 {
  color: #5bbe0d !important;
}
.order-status-10,
.order-status-11,
.order-status-12,
.order-status-13,
.order-status-14 {
  color: #ff0000;
}
</style>
<script>
export default {
  props: ["order"],
  computed: {
    orderStatus() {
      return global.vm.$t(
        `order.orderStatusDescr[${this.order.orderStatusId}]`
      );
    },
    deliveryStatusDescr() {
      return global.vm.$t(
        `order.orderDeliveryDescr[${this.order.deliveryStatusId}]`
      );
    }
  }
};
</script>
