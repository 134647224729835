<template>
  <div class="qr-code">
    <img
      class="qr-code-img"
      :src="imgsrc"
      alt="Qr Code per ritirare l'ordine"
      @click="dialog = true"
    />
  </div>
</template>
<style scoped lang="scss">
.qr-code {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 15%;
  }
  .qr-code-img {
    max-width: 100%;
  }
}
</style>
<script>
export default {
  name: "OrderQrCode",
  props: {
    codeValue: { type: String, required: true },
    width: { type: Number, default: 150 },
    height: { type: Number, default: 150 },
    fullscreen: { type: Boolean, default: false }
  },

  computed: {
    imgsrc() {
      var width = this.fullscreen ? window.innerWidth : this.width;
      var height = this.fullscreen ? window.innerHeight : this.height;
      var size = width > height ? height : width;
      if (size > 500) {
        size = 500;
      }
      // return `https://chart.googleapis.com/chart?chs=${size}x${size}&cht=qr&chl=${this.codeValue}`;
      return `https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&data=${this.codeValue}`;
    }
  }
};
</script>
