<template v-slot:activator="{ on }">
  <v-dialog
    @click:outside="onDialogClose"
    v-model="dialog"
    max-width="750px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @close="onDialogClose"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        ref="contact"
        large
        color="primary"
        class="main-button reorder-button elevation-0"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("order.contact") }}
        <v-icon class="ml-2">$phone</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-container class="contact-form px-0">
          <v-form class="d-flex flex-wrap" ref="form">
            <v-row no-gutters>
              <v-col cols="12" class="mb-3"
                ><v-btn
                  :ripple="false"
                  class="d-flex ml-auto justify-end"
                  icon
                  @click="onDialogClose()"
                  ><v-icon>$close</v-icon></v-btn
                >
              </v-col>
              <!-- TICKET TYPE -->
              <v-col cols="12">
                <v-select
                  v-if="ticketItems"
                  v-model="formData.ticketType"
                  :placeholder="`${$t('contact.requestInformation')} *`"
                  :items="ticketItems"
                  item-text="text"
                  item-value="value"
                  :rules="[requiredRules()]"
                  required
                  single-line
                  dense
                  outlined
                ></v-select>
              </v-col>
              <!-- FIRST NAME -->
              <v-col class="pr-sm-2" cols="12" sm="6">
                <v-text-field
                  v-model="formData.firstName"
                  :placeholder="`${$t('contact.name')} *`"
                  :rules="[requiredRules()]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <!-- LAST NAME -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="formData.lastName"
                  :placeholder="`${$t('contact.surname')} *`"
                  :rules="[requiredRules()]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <!-- EMAIL -->
              <v-col class="pr-sm-2 pr-md-0 pr-md-2" cols="12" sm="6">
                <v-text-field
                  v-model="formData.email"
                  :placeholder="`${$t('contact.email')} *`"
                  :rules="emailRules"
                  :error-messages="errors"
                  type="email"
                  autocomplete="off"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <!-- PREFIX-->
              <v-col cols="2" sm="1">
                <v-text-field
                  v-model="formData.prefix"
                  class="text-center"
                  placeholder="+39"
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <!-- PHONE NUMBER -->
              <v-col cols="10" sm="5">
                <v-text-field
                  v-model="formData.phoneNumber"
                  :placeholder="`${$t('contact.phoneNumber')} *`"
                  :rules="mobilePhoneRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <!-- MESSAGE -->
              <v-col class="mb-3" cols="12">
                <v-textarea
                  v-model="formData.description"
                  :rules="[requiredRules()]"
                  :placeholder="`${$t('contact.message')} *`"
                  required
                  outlined
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <h5 class="text-uppercase">
                  {{ $t("contact.informativa.title") }}
                </h5>
              </v-col>
              <!-- PRIVACY -->
              <v-col class="py-0" cols="12">
                <v-checkbox
                  :rules="checkboxRule"
                  required
                  v-model="formData.acceptPrivacyPolicies"
                >
                  <template v-slot:label>
                    <div>
                      {{ $t("contact.informativa.privacy") }}
                      <a
                        class="ml-n1"
                        target="_system"
                        href="https://laspesaonline.eurospin.it/p/wp-content/uploads/2023/04/eurospin_informativa_contatti_1_2023.pdf"
                        @click="clicked"
                      >
                        {{ $t("contact.privacyLink") }}
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onDialogClose()">
          Annulla
        </v-btn>
        <v-btn
          color="primary"
          @click.prevent.stop="validate()"
          :loading="loading"
        >
          {{ $t("contact.sendFormData") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.contact-form {
  form {
    .v-input.v-input--checkbox .v-input__slot {
      display: flex;
      align-items: flex-start;
      label {
        margin: auto 0px;
      }
    }
    .row div:nth-child(6) {
      .v-input__slot {
        padding: 0 !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        fieldset {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        input {
          text-align: center;
        }
      }
    }
    .row div:nth-child(7) {
      .v-input__slot {
        fieldset {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  form .v-input .v-input__slot {
    align-items: center;
  }
}
</style>
<script>
import {
  requiredValue,
  isEmail,
  isMobilePhone
} from "~/validator/validationRules";

import eurospinCustomService from "@/service/eurospinCustomService";
import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";

export default {
  name: "OrderContactForm",
  mixins: [clickHandler, categoryMixins],
  props: {
    order: { required: false }
  },
  data() {
    return {
      dialog: false,
      errors: [],
      loading: false,
      formData: {
        ticketType: null,
        firstName: null,
        lastName: null,
        email: null,
        prefix: null,
        phoneNumber: null,
        description: null,
        order: null,
        warehouse: null,
        acceptPrivacyPolicies: null
      },
      warehouses: null,
      ticketItems: [
        {
          text: "Richiesta di informazioni",
          value: "Informazioni Spesa Online"
        },
        { text: "Pagamento e ordini", value: "Situazione Spesa Online" },
        { text: "Reclami e segnalazioni", value: "Reclami Spesa Online" },
        { text: "Altro", value: "Informazioni Spesa Online " }
      ],
      requiredRules: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      mobilePhoneRules: [
        requiredValue("Digitare il numero di cellulare"),
        isMobilePhone()
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated"
    }),
    checkboxRule() {
      return [
        this.formData.acceptPrivacyPolicies === true || "Campo obbligatorio"
      ];
    }
  },
  methods: {
    validate() {
      let _this = this;
      if (_this.$refs.form) {
        _this.valid = _this.$refs.form.validate();
        if (_this.valid) {
          _this.loading = true;
          const formData = cloneDeep(_this.formData);

          if (_this.order) {
            formData.order = _this.order.orderId;
            formData.warehouse = _this.order.warehouse.warehouseId;
          }
          formData.prefix = "0039";
          const formDataPhoneNumber = formData.phoneNumber.trim();
          formData.phoneNumber = formDataPhoneNumber;

          const formDataEmail = formData.email.trim();
          formData.email = formDataEmail;

          eurospinCustomService
            .submitOrderTicket(formData)
            .then(function(data) {
              if (data) {
                _this.dialog = false;
                if (data.status == 0) {
                  _this.loading = false;
                  console.log("STATUS 0", data);
                } else {
                  console.log("STATUS NOT 0", data);
                  _this.$refs.form.reset();
                  _this.loading = false;
                  _this.dialog = false;
                }
              }
              console.log(data);
            });
        } else {
          const invalidFields = _this.$refs.form.$children.filter(
            field => !field.valid && field.type !== "button"
          );
          invalidFields[0].$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
          invalidFields[0].$el.focus();
        }
      }
    },
    onDialogClose() {
      this.dialog = false;
      this.$nextTick(() => {
        this.$refs.contact.$el.blur();
      });
    }
  },
  mounted() {
    this.formData.firstName = this.user.firstName;
    this.formData.lastName = this.user.lastName;
    this.formData.phoneNumber = this.user.phone;
    this.formData.email = this.user.email;
  }
};
</script>
